import { useState, useEffect } from 'react';
import { Box, Button, Stack, Typography, Switch, FormControlLabel } from '@mui/material';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'store/reducers/auth';
import { Http } from 'api/http/http';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const { name, role } = useSelector((state) => state.auth);

  const handleRefreshLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleRefreshLogout });

  useEffect(() => {
    if (role === 'admin') {
      sholarshipPeriodStatus();
    }
  }, [role]);

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    dispatch(logout());
  };

  // 장학금 신청기간 API
  const periodSwitch = async () => {
    try {
      if (!checked) {
        // 현재 상태 false: 스위치를 켜는 경우
        const response = await http.postWithToken('/api/admin/scholarships/activate');
        if (response.status === 200) {
          console.log('장학금 신청기간이 시작되었습니다.', response);
          setChecked((prev) => !prev);
        } else {
          console.error('오류가 발생했습니다.', response);
        }
      } else {
        // 현재 상태 true: 스위치를 끄는 경우
        const response = await http.postWithToken('/api/admin/scholarships/deactivate');
        if (response.status === 200) {
          console.log('장학금 신청기간이 종료되었습니다.', response);
          setChecked((prev) => !prev);
        } else {
          console.error('오류가 발생했습니다.', response);
        }
      }
    } catch (error) {
      console.log('api 호출에 오류가 발생했습니다.', error);
    }
  };

  // 장학금 신청기간 상태조회 api: 새로고침하면 상태유지가 풀리는 문제 때문에 추가된 api
  const sholarshipPeriodStatus = async () => {
    try {
      const response = await http.getWithToken('/api/admin/scholarships/period');
      if (response.status === 200) {
        console.log('장학금 신청기간 상태:', response.data);
        setChecked(response.data);
      } else {
        console.error('상태 조회 중 오류가 발생했습니다.:', response);
      }
    } catch (error) {
      console.log('장학금 신청기간 상태 조회에 오류가 발생했습니다.', error);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        {role === 'admin' && (
          <FormControlLabel
            control={<Switch checked={checked} onChange={periodSwitch} />}
            label={checked ? '장학금 신청기간 ON' : '장학금 신청기간 OFF'}
          />
        )}
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <UserOutlined sx={{ width: 32, height: 32 }} />
          <Typography variant="subtitle1">{name}</Typography>
        </Stack>
        <Button variant="contained" endIcon={<LogoutOutlined />} onClick={handleLogout}>
          로그아웃
        </Button>
      </Stack>
    </Box>
  );
};

export default Profile;
