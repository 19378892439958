// material-ui
import {
  Container,
  Card,
  Typography,
  Box,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Divider,
  Button
} from '@mui/material';

import { Http } from 'api/http/http';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StudentPasswordPermute from './StudentPasswordPermute';

// ==============================|| COMPONENTS - TYPOGRAPHY ||============================== //

const splitModulesData = (modulesData) => {
  let issuedModules = [];
  let applyingModules = [];

  modulesData.forEach((module) => {
    if (module.status === 'APPROVAL') issuedModules = [...issuedModules, module.moduleName];

    if (module.status === 'WAIT') applyingModules = [...applyingModules, module.moduleName];
  });

  return { issuedModules, applyingModules };
};

const splitScholarshipsData = (scholarshipsData) => {
  let issuedScholarships = [];
  let applyingScholarships = [];

  scholarshipsData.forEach((scholarship) => {
    if (scholarship.isConfirmed && scholarship.amount) issuedScholarships = [...issuedScholarships, scholarship.moduleName];

    if (!scholarship.isConfirmed) applyingScholarships = applyingScholarships = [...applyingScholarships, scholarship.moduleName];
  });

  return { issuedScholarships, applyingScholarships };
};

const getSubjectInfo = (subjectCode, allSubjectData, isAvailableUseCertificate, subjectName) => {
  let subjectInfo = null;
  allSubjectData.forEach(({ moduleName, process, subjectList }) => {
    const matchingSubject = subjectList.find((subject) => subject.subjectGroupId === subjectCode);
    if (matchingSubject) {
      const mappingProcess = {
        HIGH: '고급',
        MIDDLE: '중급',
        LOW: '초급'
      };
      const mappingUsed = {
        true: '모듈 이수에 사용 가능',
        false: '불가능 (타 모듈에 이미 사용됨)'
      };
      subjectInfo = {
        moduleName: moduleName,
        process: mappingProcess[process],
        subjectName: subjectName,
        available: mappingUsed[isAvailableUseCertificate]
      };
    }
  });

  return subjectInfo; // 결과 반환
};

const getSubjectInfoList = (subjectData, allSubjectData) => {
  let subjectInfoList = [];
  subjectData.forEach(({ subjectGroupId, isAvailableUseCertificate, subjectName }) => {
    const subjectData = getSubjectInfo(subjectGroupId, allSubjectData, isAvailableUseCertificate, subjectName);
    if (subjectData) subjectInfoList = [...subjectInfoList, subjectData];
  });
  return subjectInfoList;
};

const StudentHome = () => {
  const [studentInfo, setStudentInfo] = useState({
    issuedModules: [],
    applyingModules: [],
    issuedScholarships: [],
    applyingScholarships: [],
    subjectInfoList: []
  });

  const [open, setOpen] = useState(false);
  const { name } = useSelector((props) => props.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => dispatch(logout());

  const handleRefreshExpired = () => {
    navigate('/studentLogin');
  };

  const http = new Http({ onRefreshExpired: handleRefreshExpired, onLogout: handleLogout });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUserData = async () => {
    try {
      const urls = ['/api/completed-modules', '/api/scholarships', '/api/completed-subjects', '/api/modules'];
      const [modulesRawData, scholarshipsRawData, subjectsRawData, allSubjectRawData] = await Promise.all(
        urls.map((url) => http.getWithToken(url))
      );
      const modulesData = modulesRawData.data.data;
      const scholarshipsData = scholarshipsRawData.data.data;
      const subjectsData = subjectsRawData.data.data;
      const allSubjectData = allSubjectRawData.data.data;
      // console.log(subjectsData);
      const subjectInfoList = getSubjectInfoList(subjectsData, allSubjectData);
      const { issuedModules, applyingModules } = splitModulesData(modulesData);
      const { issuedScholarships, applyingScholarships } = splitScholarshipsData(scholarshipsData);

      setStudentInfo((prev) => ({
        ...prev,
        issuedModules: issuedModules,
        applyingModules: applyingModules,
        issuedScholarships: issuedScholarships,
        applyingScholarships: applyingScholarships,
        subjectInfoList: subjectInfoList
      }));
    } catch (error) {
      console.error('데이터를 불러오는데 오류가 발생했습니다. 다시 시도해주세요');
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Container sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2, marginBottom: 2 }}>
        <Typography variant="h1">{name}님의 이수 현황</Typography>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="primary" size="medium" sx={{ marginLeft: 'auto' }} onClick={handleOpen}>
            비밀번호 변경
          </Button>
          <StudentPasswordPermute open={open} onClose={handleClose} />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4, flexWrap: 'wrap', marginY: 5 }}>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="발급 받은 이수증" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'primary.main' }}
            >{`${studentInfo.issuedModules.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.issuedModules.length === 0 ? (
                <ListItem>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItem>
              ) : (
                studentInfo.issuedModules.map((module) => (
                  <ListItem key={module}>
                    <ListItemButton key={module} onClick={() => navigate('/student-certificate-management')}>
                      <ListItemText primary={module} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="발급 받은 장학증서" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'primary.main' }}
            >{`${studentInfo.issuedScholarships.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.issuedScholarships.length === 0 ? (
                <ListItem>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItem>
              ) : (
                studentInfo.issuedScholarships.map((scholarship) => (
                  <ListItem key={scholarship}>
                    <ListItemButton key={module} onClick={() => navigate('/student-scholarship-certificate-management')}>
                      <ListItemText primary={scholarship} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="신청 중인 이수증" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'warning.main' }}
            >{`${studentInfo.applyingModules.length}건`}</Typography>
          </Box>
          <CardContent>
            <List>
              {studentInfo.applyingModules.length === 0 ? (
                <ListItem>
                  <ListItemText primary={'현재 목록이 비어있습니다.'} />
                </ListItem>
              ) : (
                studentInfo.applyingModules.map((module) => (
                  <ListItem key={module}>
                    <ListItemButton key={module} onClick={() => navigate('/student-certificate-management')}>
                      <ListItemText primary={module} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <CardHeader title="신청 중인 장학증서" />
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bolder', color: 'warning.main' }}
            >{`${studentInfo.applyingScholarships.length}건`}</Typography>
          </Box>
          <CardContent>
            {studentInfo.applyingScholarships.length === 0 ? (
              <ListItem>
                <ListItemText primary={'현재 목록이 비어있습니다.'} />
              </ListItem>
            ) : (
              studentInfo.applyingScholarships.map((scholarship) => (
                <ListItem key={scholarship}>
                  <ListItemButton key={module} onClick={() => navigate('/student-scholarship-certificate-management')}>
                    <ListItemText primary={scholarship} />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </CardContent>
        </Card>
      </Box>
      <Paper sx={{ width: '100%%' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bolder' }} colSpan={4} align="center">
                  교과목 이수 현황
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">이수한 교과목 명</TableCell>
                <TableCell align="center">과정 </TableCell>
                <TableCell align="center">모듈</TableCell>
                <TableCell align="center">활용가능여부</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentInfo.subjectInfoList.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }} colSpan={4}>
                    현재 목록이 비어있습니다.
                  </TableCell>
                </TableRow>
              ) : (
                studentInfo.subjectInfoList.map((subjectInfo) => (
                  <TableRow key={subjectInfo.subjectName}>
                    <TableCell align="center">{subjectInfo.subjectName}</TableCell>
                    <TableCell align="center">{subjectInfo.process}</TableCell>
                    <TableCell align="center">{subjectInfo.moduleName}</TableCell>
                    <TableCell align="center">{subjectInfo.available}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default StudentHome;
