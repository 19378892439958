import { Grid, Typography, Container, Card, Box, Link } from '@mui/material';

const StudentInquiry = () => {
  const copyEmail = async (email) => {
    try {
      await navigator.clipboard.writeText(email);
      alert('메일주소가 클립보드에 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패했습니다.');
    }
  };

  return (
    <Container>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card sx={{ padding: '30px', marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
              지능로봇 혁신 공유대학 사업단
            </Typography>
            <Typography variant="h5" gutterBottom>
              02-940-8431
            </Typography>
            <Typography variant="body1">
              마이크로모듈 관련 정책 담당자 이메일:
              <Link
                component="button"
                variant="body1"
                onClick={() => copyEmail('share@kw.ac.kr')}
                style={{ paddingLeft: 3, paddingBottom: 3, textTransform: 'none', textDecoration: 'underline' }}
              >
                share@kw.ac.kr
              </Link>
            </Typography>
            <Typography variant="body1">
              시스템 오류 담당자 이메일:
              <Link
                component="button"
                variant="body1"
                onClick={() => copyEmail('kw.share.service@gmail.com')}
                style={{ paddingLeft: 3, paddingBottom: 3, textTransform: 'none', textDecoration: 'underline' }}
              >
                kw.share.service@gmail.com
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ height: '20px' }} />
    </Container>
  );
};

export default StudentInquiry;
