import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

function SubjectListCard({ lecture, currentModule, selected, addLecture, wholeSubjects, completedSubjects }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [standardSubject, setStandardSubject] = useState('');

  useEffect(() => {
    const newChanged = selected.changed;
    if (newChanged !== changed && currentModule !== selected.module) {
      setSelectedOption('');
      setFirstCheck(false);
    }
    setChanged(newChanged);
  }, [selected]);

  useEffect(() => {
    console.log(selectedValue, firstCheck);
  }, [selectedValue]);

  useEffect(() => {
    const lectureList = lecture.split(',');
    if (lectureList.length > 0) {
      setStandardSubject(lectureList[0]);
    }
  }, [lecture]);

  const getSubjectCode = (data, userSubjectName) => {
    let subjectCode;
    data.some((item) => {
      const foundSubject = item.subjectList.find((subject) => subject.subjectName === userSubjectName);
      if (foundSubject) {
        subjectCode = foundSubject.subjectGroupId;
        return true;
      }
    });
    return subjectCode;
  };

  const isCompleted = (data = null, userSubjectCode) => {
    if (data) {
      return data.some((subject) => subject.subjectGroupId === userSubjectCode && subject.isAvailableUseCertificate === true);
    }
    return false;
  };

  const isCompletedSubjects = (lectures = []) => {
    const isCompletedArray = [];
    const subjectsCode = [];
    lectures.forEach((subjectName, index) => {
      if (index !== 0) {
        const subjectCode = getSubjectCode(wholeSubjects, subjectName);
        const isCompletedSubject = isCompleted(completedSubjects, subjectCode);
        subjectsCode.push(subjectCode);
        if (isCompletedSubject) {
          isCompletedArray.push(true);
        } else {
          isCompletedArray.push(false);
        }
      }
    });

    return isCompletedArray;
  };

  const isCompletedArray = isCompletedSubjects(lecture.split(','));
  const isAllFalse = isCompletedArray.every((value) => value === false);

  const handleConfirm = () => {
    setOpen(false);
    setFirstCheck(true);
    setSelectedOption(selectedValue);
    addLecture((prevState) => ({
      selectedLectures: [selectedValue],
      module: currentModule,
      changed: !prevState.changed,
      credit: 3
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    if (selected.module !== '' && currentModule !== selected.module) {
      const currentSelected = event.target.value;
      setSelectedValue(currentSelected);

      setOpen(true);
    } else {
      const existingLecture = selectedOption;
      const currentSelected = event.target.value;

      addLecture((prevState) => ({
        ...prevState,
        module: currentModule
      }));

      if (currentSelected === '') {
        console.log(currentSelected);
        setSelectedOption(currentSelected);
        setFirstCheck(false);
        addLecture((prevState) =>
          prevState.credit - 3
            ? {
                ...prevState,
                selectedLectures: [...prevState.selectedLectures].filter((lec) => lec !== existingLecture),
                credit: firstCheck ? prevState.credit - 3 : prevState.credit
              }
            : { selectedLectures: [], module: '', changed: false, credit: 0 }
        );
      } else {
        if (selected !== existingLecture) {
          const creditAfterAdd = firstCheck ? selected.credit : selected.credit + 3;
          if (creditAfterAdd > 13) {
            alert('학점은 13학점을 초과할 수 없습니다');
          } else {
            setSelectedOption(currentSelected);
            addLecture((prevState) => ({
              ...prevState,
              selectedLectures: [...prevState.selectedLectures].filter((lec) => lec !== existingLecture).concat(currentSelected),
              credit: firstCheck ? prevState.credit : prevState.credit + 3
            }));
            if (!firstCheck) setFirstCheck(true);
          }
        }
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'다른 모듈의 과목을 선택하시나요?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            다른 모듈 과목을 선택하면 기존 모듈에서 선택했던 과목이 취소 됩니다. 그래도 계속 하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} autoFocus>
            예
          </Button>
          <Button onClick={handleClose}>아니오</Button>
        </DialogActions>
      </Dialog>

      <Paper
        sx={{
          padding: '7px',
          backgroundColor: isAllFalse ? 'grey.300' : selectedOption ? 'primary.main' : 'white',
          color: isAllFalse ? 'grey.500' : selectedOption ? 'white' : 'black'
        }}
      >
        <Box margin={1}>
          <Typography>{standardSubject}</Typography>
        </Box>
        <FormControl fullWidth>
          <InputLabel sx={{ paddingY: 0.25 }} id="demo-simple-select-label">
            과목 선택 ( 택 1 )
          </InputLabel>
          <Select
            value={selectedOption}
            onChange={handleChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="과목 선택"
            sx={{
              backgroundColor: selectedOption ? 'white' : isAllFalse ? 'grey.300' : 'white', // 선택된 경우 흰색, 배경 회색일 경우 회색
              border: '1px solid',
              borderColor: 'grey.400', // 테두리 색상 설정
              '& .MuiSelect-icon': {
                color: isAllFalse ? 'grey.500' : 'black' // 드롭다운 아이콘 색상 조정
              }
            }}
            disabled={isAllFalse}
          >
            <MenuItem value="">선택하지 않음</MenuItem>
            {lecture.split(',').map(
              (item, index) =>
                index !== 0 && (
                  <MenuItem key={item} value={item} disabled={!isCompletedArray[index - 1]}>
                    {item}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </Paper>
    </>
  );
}

export default SubjectListCard;
